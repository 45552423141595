body.landing {
    /*background-color: #FFFFFF;*/
    background-color: var(--colorbackground);
    width: 100%;
    margin: 0;
    padding: 0;
}
body.landing main {
    background-color: var(--colorbackground);
    margin-top: 210px;
    margin-bottom:0px;
}

body.landing.naranja {
    background-color: var(--Background-naranja);
}
body.landing main.naranja {
    background-color: var(--Background-naranja);
}
body.landing main.naranja.mobile {
    background-color: var(--Background-naranja);
}
body.landing main.mobile {
    margin-top:186px;
}
body.landing .desktop {
    width: var(--max-width);
}
body.landing .container-fluid {
    margin-bottom: 120px;
}
body.landing .logo {
    width: 256px;
    position: relative;
    left: -20px;
}
body.landing .h1 {
    text-shadow: 0px 0px 14px #f8f9ff;
    font-family: Poppins;
    font-weight: 700;
    color: var(--texttitulares);
    font-size: 55px;
    letter-spacing: -1.375px;
    line-height: 130%;
}
body.landing .text-p {
    color: var(--textcolor-texto);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
}
body.landing .text-p.medium {
    font-size: medium;
    line-height: 150%;
}
body.landing .text-p-bold {
    color: var(--textcolor-texto);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
}

body.landing .text-p .text-p-bold {
    font-weight: 900;
}
body.landing .button {
    all: unset;
    box-sizing: border-box;
    display: inline-flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px 50px;
    position: relative;
    background-color: var(--colorazul);
    border-radius: 20px;
    transition: all 0.3s ease;
}
body.landing .label {
    /*position: relative;*/
    width: fit-content;
    font-family: "Poppins";
    font-weight: 450;
    color: var(--colorwhite);
    font-size: 20px;
    text-align: center;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
}
body.landing .arrow-left {
    /*position: relative;*/
    width: 20px;
    height: 20px;
    background-image: url(../img/arrow-right.svg);
    background-size: 100% 100%;
}
body.landing .stock-photo-group-of {
    width: 670px;
    height: 700px;
    object-fit: cover;
}

body.landing .stock-photo-cheerful {
    width: 600px;
    height: 610px;
    object-fit: cover;
}

body.landing .h2 {
    font-family: Poppins;
    font-weight:700;
    color: var(--texttitulares);
    font-size: 50px;
    letter-spacing: -1px;
    line-height: 120%;
    align-self: stretch;
}


body.landing .h3 {
    color: var(--texttitulares);
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -1px;
}

body.landing .feature-card {
    display: flex;
    width: 285px;
    height: 285px;
    padding: 40px 20px ;
    margin: 20px 10px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--CTA---Aqua-Squeeze, #E5F4F2);
    background: var(--colorwhite);
    box-shadow: var(--sombra-azul);
    gap:30px;
    padding-bottom: 5px;
    text-align: center;
}
body.landing .feature-card-mobile {
    display: flex;
    width: 97%;
    height: 85%;
    padding: 40px 20px ;
    margin: 20px 10px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--CTA---Aqua-Squeeze, #E5F4F2);
    background: var(--colorwhite);
    box-shadow: var(--sombra-azul);
    gap:30px;
    padding-bottom: 5px;
    text-align: center;
}

body.landing .feature-card-big {
    display: flex;
    padding: 40px 20px ;
    margin: 20px 10px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--CTA---Aqua-Squeeze, #E5F4F2);
    background: var(--colorwhite);
    box-shadow: var(--sombra-azul);
    gap:30px;
}

body.landing .card-icon {
    margin: 20px 0;
    width: 90px;
    height: 90px;
}

body.landing .card-title {
    font-family: Poppins;
    font-size: 24px;/*28px;*/
    font-style: bold;
    font-weight: 450;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: var(--texttitulares);
}

body.landing .card-text {
    color: var(--texttitulares);
    letter-spacing: 0;
    line-height: 30px;
    font-family: Mulish;
    font-size: 16px;/*20px;*/
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    
}


body.landing .container-contact-form {
    border-radius: 20px;
    border: 1px solid var(--CTA---Aqua-Squeeze, #E5F4F2);
    background: var(--colorwhite);
    box-shadow: var(--sombra-azul);
    padding: 60px 0;
}

body.landing .form-fields {
    gap: 24px;
    align-self: stretch;
}

body.landing .input {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Black-1, #CFD3D4);
    margin-bottom: 25px;
}

body.landing .input-label {
    align-self: stretch;
    color: var(--Black-4, #5E6366);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

body.landing .input-field {
    align-self: stretch;
    color: var(#5E6366);
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    border-color: transparent
}

body.landing .check-text {
    color: var(--Text-Titulares, #2D2D2D);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

body.landing .form-check {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 6px;
    border-color: transparent;
    /*background: var(--colorazul, #084796);
    background-image: url(img/fi-no-check.svg);
    background-color: var(--colorazul);*/
}

body.landing .container-footer {
    padding: 24px 60px;
    background-color: var(--texttitulares);
    margin-bottom:0px;
}

body.landing .footer-text {
    color: var(--colorwhite);
    font-family: Mulish;
    font-size: medium;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
}


body.landing .ilabel {
    width: fit-content;
    font-family: "Poppins";
    font-weight: 450;
    color: var(--colorwhite);
    font-size: 20px;
    text-align: center;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
}


body.landing .proteccion-datos-header {
    color: var(--texttitulares);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
body.landing .proteccion-datos-text {
    color: var(--texttitulares);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



body.landing .pinwheel-background {
    position: relative;
    width: 172px;
    height: 172px;
    background-image: url(../img/ellipse-1.png);
    background-size: 100% 100%;
}
body.landing .pinwheel-background-mobile {
    position: relative;
    width: 152px;
    height: 152px;
    background-image: url(../img/ellipse-1.png);
    background-size: 100% 100%;
}

body.landing .pinwheel-icon {
    width: 70px;
    height: 70px;
    margin-top: 20px;
    margin-left: 40px;
}
body.landing .pinwheel-icon-mobile {
    width: 50px;
    height: 50px;
    margin-top: 15px;
    margin-left: 30px;
}

body.landing .pinwheel-text {
    font-family: "Poppins";
    font-weight: 600;
    color: var(--colorwhite);
    font-size: 18px;
    text-align: center;
    letter-spacing: 0;
    line-height: 21.5px;
}
body.landing .pinwheel-text-mobile {
    font-family: "Poppins";
    font-weight: 600;
    color: var(--colorwhite);
    font-size: 14px;
    text-align: center;
    letter-spacing: 0;
    line-height: 21.5px;
}



body.landing .item {
    position:relative;
    padding-top:0px;
    display:inline-block;
}
body.landing .notify-badge{
    position: absolute;
    right:-30px;
    top:-18px;
}

body.landing .header{
    top:0;
    left:0;
    width: calc(100% - 120px);
    padding: 25px 60px;
    justify-content: space-between;
    align-items: center;
    background: var(--Blanco);
    box-shadow: -5.15px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
}

header.header{
    top:0;
    left:0;
    width: calc(100% - 120px);
    padding: 25px 60px;
    justify-content: space-between;
    align-items: center;
    background: var(--Blanco);
    box-shadow: -5.15px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
}
body.landing .justify {
    text-align: justify;
}

body.landing .left {
    text-align: left;
}

body.landing .hidden {
    visibility: hidden;
}
body.landing .desktop .overlap-rgpd {
    /*width: var(--column-width);*/
    position: absolute;
    /*top: 300px;*/
    /*left: 96px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:0;
    padding-right:0;
}


.overlap-rgpd .text-p {
    color: var(--textcolor-texto);
    font-family: Mulish;
    font-size: medium;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
    font-size: medium;
}
.overlap-rgpd .text-p-400 {
    color: var(--textcolor-texto);
    font-family: Mulish;
    font-size: medium;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
    font-size: medium;
}
.overlap-rgpd .text-p p {
    font-size: medium;
}
.overlap-rgpd .text-p span {
    font-size: medium;
}

 .overlap-rgpd .text-p-azul {
    color: var(--colorazul);
    font-family: Mulish;
    font-size: medium;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
}
body.landing .text-p-azul {
    color: var(--colorazul);
}
.overlap-rgpd .text-p .text-p-bold {
    font-weight: 700;
}

.overlap-rgpd .text-p a {
    text-decoration: none;
    color: var(--colorazul);
  }
  
 .overlap-rgpd .logo {
    position: relative;
    width: 128px;
    /*top: 20px;*/
    left: -20px;
}

body.landing .overlap-rgpd-footer {
    /*display: flex;*/
    width: var(--column-width);
    /*padding: 24px 60px;
    /*flex-direction: row;*/
    /*justify-content: center;
    align-items: center;*/
    background-color: var(--texttitulares);
    position: absolute;
    bottom: 0px;
    height: 50px;
    min-height:50px;
    /*width: 100%;*/
    /*height: 2.5rem;            /* Footer height */
}


body.landing .h4 {
    color: var(--texttitulares);
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -1px;
}

body.landing .h5 {
    color: var(--texttitulares);
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /*line-height: 130%;*/
    letter-spacing: -1px;
}

body.landing .bootRow {
    display: 'flex';
    flex-direction: 'row'; 
    justify-content: 'space-between';

}
body.landing .bootColumn {
    display: 'flex';
    flex-direction: 'row'; 
    justify-content: 'space-between';

}

body.landing .rectangle {
    width: 43%;
    height: 95px;
    gap: 0px;
    border-radius: 30px 30px 0px 0px;
    border: 1px 1px 0px 1px;
    border-width: thin;
    border-style: solid;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
}
body.landing .rectangle.mobile {
    height: 70px;
}

body.landing .rectangle.mobile p{
    font-size: 18px;
}

body.landing .rectangle.active {
    background-color: #F2FCFF;
    border-color: #D2D2D2;
    border-bottom-color: var(--colorbackground);
}
body.landing .rectangle.active.naranja {
    background-color: var(--Background-naranja);
}
body.landing .rectangle.inactive {
    background-color: var(--Blanco);
    border-color: #D2D2D2;
}


body.landing .tabs {
    margin:0;
    padding:0;
    width: 100%;
    top: 100px;
    background: var(--Blanco);
    background-color: var(--Blanco);
    border-color: #D2D2D2;
    border-width: thin;
    border-style: solid;
    border-top:0;
    border-left:0;
    border-right:0;
}

body.landing .tabs p {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 32.5px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--texttitulares);
    text-decoration: none;
}

body.landing .tabs a {
    text-decoration: none;
}



body.landing section{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 25px;
    row-gap: 25px;
    max-width: 90%;
    position: relative;
    background-color: var(--Blanco);
    border-radius: var(--Radius-L);
    background: var(--Blanco);
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
    padding: 60px 60px 60px 60px;
    margin-top: -25px;
    margin-bottom: 25px;
}