/*** RESPONSIVE ***/
/*769 - 1024*/
@media only screen and (min-width: 769px) and (max-width: 1023px) {
    /*CARDS*/
        article.card{
            width: 46.5%;
        }
        main > section.contratos > article.card{
            width: 30%;
        }
        main > section.contratos > article.contratos{
            width: 55.5%;
        }
        main > section{
            padding: 0px 30px;
        }
        main.desktop {
            width: 1023px;
        }
}

/* 769 - 1000 */
@media only screen and (min-width: 769px) and (max-width: 1000px) {
    header .input-group{
        max-width: 400px;
    }
    main.desktop {
        width: 100%;
    }
}

/* 300 - 768*/
@media only screen and (min-width: 300px) and (max-width: 768px) {
    /*ESTILOS*/
        :root {
            --Icons: 28px;
            --Icons-S: 20px;
            --XXL: 32px;
            --XL: 24px;
            --L: 18px;
            --M: 14.5px;
            --S: 13.5px;
            --XS: 12px;
        }
        main.desktop {
            width: 100%;
        }
    /*BODY*/
        .boton, a.boton{
            padding: 7px 15px;
            min-width: 90px;
        }
        .botones{
            width: 100%;
            margin-bottom: 20px;
        }
    /*HEADER*/
        header .logo{
            display: none;
        }
        header .boton,
        header a.menu span{
            display: none;
        }
        header .input-group{
            width: 80%;
        }
    /*MENU LATERAL*/
        aside{
            position: fixed;
            right:0px;
            top:0px;
            width: calc(100vw - 60px);
        }
        aside .mapa{
            grid-template-rows: repeat(11,1fr);
        }
        aside .mapa iframe{
            grid-row-start: 1;
            grid-row-end: 9;
        }
        aside .mapa .slide{
            grid-row-start: 9;
            grid-row-end: 9;
        }
        aside .mapa .boton{
            grid-row-start:10;
            grid-row-end: 10;
        }
    /*HEADER & FOOTER MENU*/
        header{
            padding: 20px 30px;
            width: calc(100% - 60px);
        }
        header .logo{
            display: none;
        }
        footer{
            width: calc(100% - 60px);
        }
        footer nav{
            padding: 15px;
            column-gap: 15px;
            width: calc(100% - 30px);
        }

        footer nav.candidato{
            column-gap: 5px;
        }
    /*MAIN*/
        main{
            padding: 0px 30px;
            margin: 110px 0px 110px 0px;
        }
        main > section{
            max-width: 100%;
        }
        body.ficha main section article{
            min-width: 100%;
        }
        body.noheader article.fullwidth,
        article.width60{
            width: 100%;
            padding:0px;
        }
        
    /*NO HEADER*/
        body.noheader{
            background-color: var(--Blanco);
        }
        body.noheader header{
            display: none;
        }
        body.noheader main{
            margin-top: 30px;
        }
        body.noheader.ficha main{
            margin-top: 0px;
        }
        body.noheader main *{
            box-shadow: none !important;
        }
    /*CARDS*/
        article.card,
        article.card a{
            width: 100%;
            flex-direction: row;
        }
        article.card figure.image{
            width: 35%;
            height: 100%;
        }
        article.card figure.image img {
            width: 100%;
            height: fit-content;
            object-fit: fill;
            float: left;
        }
        article.card .card_content{
            width: calc(65% - 30px);
            padding: 10px 15px;
            line-height: 140%;
        }
        article.card.plan,
        article.card.plan a{
            width: calc(100% - 30px);
        }
    /*FICHAS*/
        body.ficha figure:after{
            top:0;
            left:0;
            width: 100%;
            height: 150px;
            background: linear-gradient(to bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%) !important;
            backdrop-filter: inherit;
            z-index: 2;
        }
        body.ficha main {
            padding:0px;
        }
        body.ficha article {
            min-width: 100%;
        }
        body.ficha main > section {
            padding: 40px 30px 120px 30px;
            width: calc(100% - 60px);
            background-color: var(--Background);
        }
        body.ficha main a.back{
            top:100px;
            left:30px;
        }
        body.ficha figure{
            height: fit-content;
        }
        body.ficha figure img{
            width: 100vw;
        }
        .like{
            right:30px;
        }
        .quiero{
            left:30px;
            background-color: var(--Blanco);
        }
    /*OPTIONS*/
        .option {
            padding: 15px 0px;
        }
        .option > i,
        .option > input[type="checkbox"]{
            top:25px;
        }
    /*CONTRATOS*/
        article.contratos{
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }
    /*ALERT*/
        .alert.fixed{
            position: fixed;
            right: 50%;
            transform: translateX(50%);
            max-width: inherit;
            z-index: 8;
        }
        .lity-content .alert,
        .alert.fixed{
            width: calc(100% - 100px);
        }
    /*SETUP*/
        section.setup{
            width: calc(100% - 60px);
            height: calc(100% - 130px);
            border-radius: 0px;
            max-height: inherit;
            padding-bottom: 100px;
        }
}

/* ALTURA MAPA */
@media only screen and (min-height: 700px) {
    aside .mapa{
        grid-template-rows: repeat(13,1fr);
    }
    aside .mapa iframe{
        grid-row-start: 1;
        grid-row-end: 12;
    }
    aside .mapa .slide{
        grid-row-start: 12;
        grid-row-end: 12;
    }
    aside .mapa .boton{
        grid-row-start:13;
        grid-row-end: 13;
    }
}