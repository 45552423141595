/*ESTILOS*/
:root {
    --Blanco: #ffffff;
    --Background: #F2FCFF;
    --Boton1-Hover: #EEFBFF;
    --Boton2-Hover: #063978;
    --Texto: #4D4D4D;
    --Titulares: #2D2D2D;
    --Azul: #084796;
    --Strokes: #D2D2D2;
    --Gris-Valoraciones: #D2D2D2;
    --WhatsApp: #25D366;
    --Placeholder: #6B6B6B;
    --Radius-XS: 7px;
    --Radius-S: 10px;
    --Radius-M: 20px;
    --Radius-L: 30px;
    --Icons: 30px;
    --Icons-L: 38px;
    --Icons-S: 23px;
    --XXL: 40px;
    --XL: 28px;
    --L: 20px;
    --M: 16px;
    --S: 14px;
    --XS: 12px;
    --Bold: 700;
    --SemiBold: 600;
    --Regular: 400;
    --Thin: 300;
    --Transition: all 0.5s ease;
    --Scale: scale(1.025);
    --Background-naranja: #f9e7d6;/*#fffdf2;*/
    

    /* Landing */
    --texttitulares: rgba(45, 45, 45, 1);
    --textcolor-texto: rgba(77, 77, 77, 1);
    --colorplaceholder: rgba(107, 107, 107, 1);
    --colorbackground: rgba(242, 252, 255, 1);
    --colorazul: rgba(8, 71, 150, 1);
    --colorazul-hover: rgba(6, 57, 120, 1);
    --colorwhite: rgb(17, 16, 16);
    --colorgray: rgba(62, 62, 62, 1);
    --colorwhatsapp: rgba(37, 211, 102, 1);
    --colormapa: rgba(255, 255, 255, 0.6);
    --colorprimary-hover: rgba(238, 251, 255, 1);
    --colornaranja: rgba(242, 164, 93, 1);
    --colornaranja-hover: rgba(242, 126, 68, 1);
    --colorstrokes: rgba(210, 210, 210, 1);
    --colorwhite: rgb(255, 255, 255);
    --sombra: 0px 20px 50px 0px rgba(76, 76, 76, 0.04);
    --sombra-azul: 35px 30px 48px 0px rgba(8, 71, 150, 0.05);
    --sombra-estilo-2: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

/*BODY*/
body {
    background: var(--Background);
    font-family: 'Mulish', sans-serif;
    font-size: var(--M);
    color: var(--Texto);
    line-height: 140%;
}

body i {
    font-size: var(--Icons);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    font-family: 'Poppins', sans-serif;
    font-weight: var(--Bold);
    line-height: 135%;
}

h1 {
    color: var(--Titulares);
    font-size: var(--XL);
    text-align: center;
    font-weight: var(--Bold);
    line-height: 150%;
    width: 100%;
}

h2 {
    color: var(--Titulares);
    font-family: Poppins;
    font-size: var(--L);
    font-weight: var(--SemiBold);
}

h3 {
    color: var(--Titulares);
    font-size: var(--M);
    font-weight: var(--SemiBold);
}

h4 {
    font-weight: var(--SemiBold);
}

.headers {
    display: flex;
    justify-content: center;
    width: 100%;
}

.headers .back {
    align-self: flex-start;
    color: var(--Texto);
}

p {
    margin-bottom: 15px;
}

p.small,
.small {
    font-size: var(--XS);
    margin-bottom: 7.5px;
}

p a {
    color: var(--Azul);
}

p strong,
p b {
    font-weight: var(--Bold);
}

.mt {
    margin-top: 20px;
}

.mtx2 {
    margin-top: 40px;
    ;
}

.mb {
    margin-bottom: 20px;
}

.mbx2 {
    margin-bottom: 40px;
}

.boton {
    display: flex;
    padding: 8px 15px;
    min-width: 125px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-XS);
    border: 1px solid var(--Azul);
    background: var(--Blanco);
    transition: var(--Transition);
    color: var(--Azul);
    text-align: center;
    font-family: Poppins;
    font-weight: var(--SemiBold);
    text-decoration: none;
    flex-grow: 1;
    cursor: pointer;
}

.boton:hover {
    transform: var(--Scale);
}

.boton i {
    margin-right: 10px;
    font-size: var(--Icons);
}

.boton.small {
    font-size: var(--S);
    padding: 7px 14px;
}

.boton.small i {
    font-size: var(--Icons-S);
}

.boton:hover {
    color: var(--Azul);
    background: var(--Boton1-Hover);
}

.boton.selected {
    background-color: var(--Azul);
    color: var(--Blanco);
}

.boton.selected:hover {
    background-color: var(--Boton2-Hover);
}

.boton.selected i {
    color: var(--Blanco);
}

.boton.selected.minus {
    max-width: fit-content;
}

.boton.naranja {
    background-color: var(--colornaranja);
    color: var(--Blanco);
    border: 1px solid var(--colornaranja);
}

.boton.naranja:hover {
    background-color: var(--colornaranja-hover);
}

.botones {
    width: 70%;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    background-color: var(--Background);
    margin-bottom: 40px;
}

.boton.whatsapp,
.boton.whatsapp:hover {
    border: 1px solid var(--WhatsApp);
    background-color: var(--WhatsApp);
    color: #fff;
}

.boton.blanco {
    border: 1px solid var(--Blanco);
    background-color: var(--Blanco);
    color: var(--Azul);
}

.boton.blanco:hover {
    background-color: var(--Background);
}

.boton.terciario {
    border: 0px;
    background-color: transparent;
    color: var(--Azul);
    font-weight: var(--Regular);
}

.boton.terciario.blanco {
    color: var(--Blanco);
    background-color: transparent;
}

.boton.terciario.blanco:hover {
    color: var(--Background);
}

.back,
a.back {
    text-decoration: none;
    transition: var(--Transition);
}

.back:hover,
a.back:hover {
    transform: var(--Scale);
}

/*HEADER & FOOTER MENU*/
header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: calc(100% - 120px);
    /*width:100%;*/
    padding: 25px 60px;
    justify-content: space-between;
    align-items: center;
    background: var(--Blanco);
    box-shadow: -5.15px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
    z-index: 4;
}

header .input-group {
    max-width: 500px;
    cursor: pointer;
}

a.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--Texto);
    font-family: Poppins;
    font-size: var(--S);
    font-weight: var(--Regular);
    text-decoration: none;
    transition: var(--Transition);
    line-height: 150%;
    position: relative;
}

a.menu.selected,
a.menu:hover {
    color: var(--Azul);
}

a.menu::after {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 0px;
    height: 5px;
    background-color: var(--Azul);
    transform-origin: center;
    transition: var(--Transition);
}

a.menu:hover::after,
a.menu.selected::after {
    width: 65px;
}

a.menu i {
    font-size: var(--Icons);
}

footer {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 4;
    width: 640px;
}

footer.top {
    z-index: 10;
}

footer nav {
    display: flex;
    padding: 20px 25px;
    justify-content: space-evenly;
    column-gap: 25px;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--Radius-M)var(--Radius-M) 0px 0px;
    background: var(--Blanco);
    box-shadow: 0px 24.496px 61.241px 0px rgba(76, 76, 76, 0.04);
}

/*MAIN*/
main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 200px 0px;
}

main>section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 25px;
    row-gap: 25px;
    max-width: 820px;
    justify-content: space-evenly;
    position: relative;
}

/*CARDS*/
article.card h3 {
    font-weight: var(--SemiBold);
}

article.card {
    /*width: 31%;*/
    width: 350px;
    height: auto;
    border-radius: var(--Radius-S);
    background: var(--Blanco);
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
    overflow: hidden;
    transition: var(--Transition);
    cursor: pointer;
}

article.card,
article.card a {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

article.card * {
    text-decoration: none;
    color: var(--Texto);
}

article.card p {
    color: var(--Texto);
}

article.card:hover {
    transform: var(--Scale);
}

article.card .card_content {
    position: relative;
    padding: 15px 20px;
    font-weight: var(--Regular);
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

article.card figure.image,
article.card figure.image img {
    width: 100%;
    height: auto;
    object-fit: fill;
}

article.card .card_content>i,
article.card .card_content>input {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: var(--Icons);
}

article.card .card_content p {
    margin-bottom: 0px;
}

article.card .card_content .valoraciones {
    margin-bottom: 0px;
    margin-top: 8px;
}

article.card .card_content .small {
    font-size: var(--XS);
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: backwards;
}

/*PLANES*/
body.plan h2 {
    font-size: var(--XXL);
    text-align: center;
    line-height: 120%;
    font-weight: var(--Bold);
}

body.plan h2 span {
    font-weight: var(--Thin);
    display: inline-block;
}

body.plan h2 span.iva {
    font-size: var(--L);
    display: block;
}

article.card.plan {
    width: 27%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 15px;
    row-gap: 15px;
    overflow: visible;
}

article.card.plan a.boton {
    align-self: center;
}

article.card.plan h2 {
    font-size: var(--XL);
}

article.card.plan h3 {
    font-size: var(--XL);
    font-weight: var(--Bold);
    display: flex;
    flex-direction: column;
    align-items: center;
}

article.card.plan h3 span {
    font-weight: var(--Thin);
    font-size: var(--M);
}

article.card.plan .options {
    row-gap: 7px;
    width: fit-content;
}

article.card.plan .options .plan_option {
    display: flex;
    align-content: center;
    column-gap: 10px;
}

article.card.plan .options .plan_option.ko {
    opacity: 0.5;
}

article.card.plan .options i {
    font-size: var(--Icons-S);
}

/*FORMS*/
.input-group {
    display: flex;
    column-gap: 10px;
    position: relative;
    width: 100%;
}

.input-group .input {
    display: flex;
    column-gap: 15px;
    position: relative;
    width: 100%;
}

.input-group i {
    position: absolute;
    left: 10px;
    top: 5px;
    font-size: var(--Icons);
}

.input-group i.right {
    position: absolute;
    left: inherit;
    right: 10px;
}

.input-group label,
label {
    color: var(--Texto);
    font-weight: var(--SemiBold);
    line-height: 150%;
    font-size: var(--M);
}

.input-group input,
.input-group select,
.input-group textarea {
    border-radius: var(--Radius-S);
    border: 1px solid var(--Strokes);
    background: var(--Blanco);
    padding: 10px 10px 10px 60px;
    font-family: Mulish;
    font-size: var(--M);
    font-weight: var(--Regular);
    width: 100%;
}

.input-group.noicon input,
.input-group.noicon select,
.input-group.noicon textarea {
    padding-left: 10px;
}

.input-group textarea {
    min-height: 100px;
    width: calc(100% - 70px);
    min-width: calc(100% - 70px);
}

.input-group select option {
    margin-right: 20px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

header .input-group input {
    padding-right: 60px;
}

/*MENU LATERAL*/
.overlay {
    position: fixed;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0px;
    margin: 0px;
    padding: 0px;
}

.lity-content {
    display: flex;
    justify-content: center;
}

.lity {
    background: rgba(0, 0, 0, 0.8) !important;
}

aside {
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: var(--Blanco);
    width: 400px;
    height: calc(100% - 60px);
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    /*transform: translateX(-100%); */
    /*transition: transform 0.3s ease-in-out; */
}

aside i {
    font-size: var(--Icons);
}

aside>div.titular {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    column-gap: 15px;
}

aside>div.titular a {
    text-decoration: none;
    color: var(--Texto);
}

aside article.card,
aside article.card a {
    width: 100%;
    flex-direction: row;
    box-shadow: none !important;
    border: 1px var(--Strokes) solid;
    cursor: pointer;
}

aside article.card figure.image {
    width: 35%;
    height: 100%;
}

aside article.card figure.image img {
    width: 100%;
    height: fit-content;
    object-fit: fill;
    float: left;
}

aside article.card .card_content {
    width: calc(65% - 30px);
    padding: 10px 15px;
    line-height: 140%;
}

aside article.card.plan,
aside article.card.plan a {
    width: calc(100% - 30px);
}

aside .mapa {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: 100%;
    row-gap: 20px;
    height: 100%;
    width: 100%;
    border-bottom: 0px;
}

aside .mapa iframe {
    width: 100%;
    height: 100%;
    border-radius: var(--Radius-M);
    grid-row-start: 1;
    grid-row-end: 11;
}

aside .mapa .slide {
    width: 100%;
    height: 1px;
    background-color: var(--Azul);
    position: relative;
    grid-row-start: 11;
    grid-row-end: 11;
}

aside .mapa .slide span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: -10px;
    transform: translateX(-);
    background-color: var(--Azul);
}

aside .mapa .boton {
    grid-row-start: 12;
    grid-row-end: 12;
}

/*aside.open {
        transform: translateX(0); 
    }
    aside.closed {
        transform: translateX(-100%); 
    }*/
/*FICHA*/
a.back.responsive {
    display: none;
}

body.ficha main {
    margin-top: 120px;
    margin-bottom: 0px;
}

body.ficha main a.back {
    position: absolute;
    top: -350px;
    left: 0px;
    z-index: 2;
    color: var(--Blanco);
}

body.ficha main span.back {
    position: absolute;
    top: -350px;
    left: 0px;
    z-index: 2;
    color: var(--Blanco);
}

body.ficha figure {
    position: relative;
    background-position: center;
    display: flex;
    justify-content: center;
    background-size: cover;
    width: 100%;
    overflow: hidden;
    height: 400px;
}

body.ficha figure:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    z-index: 1;
}

body.ficha figure img {
    position: relative;
    z-index: 2;
    height: auto;
    object-fit: fill;
}

body.ficha main>section {
    background-color: var(--Blanco);
    border-radius: var(--Radius-L) var(--Radius-L) 0px 0px;
    background: var(--Blanco);
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
    padding: 60px 90px 120px 90px;
    margin-top: -25px;
    z-index: 3;
}

body.ficha main h1 {
    text-align: left;
    margin-bottom: 20px;
}

body.ficha main section article {
    min-width: 600px;
}

.like {
    position: absolute;
    top: -30px;
    right: 85px;
    border-radius: 100%;
    background-color: var(--Blanco);
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.07));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--Transition);
}

.like:hover {
    transform: var(--Scale);
}

.like i {
    font-size: var(--Icons-L);
}

i.ph-heart {
    cursor: pointer;
}

i.ph-fill.ph-heart {
    color: #FF4040;
}

.quiero {
    position: absolute;
    top: -20px;
    left: 85px;
    border-radius: var(--Radius-S);
    border: 1px solid var(--Placeholder);
    background: var(--Background);
    display: inline-flex;
    padding: 4px 10px;
    align-items: center;
    gap: 5px;
}

/*OPTION*/
.options {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.option {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--Strokes);
    position: relative;
    row-gap: 8px;
    padding: 20px 0px;
    cursor: pointer;
    justify-content: space-between;
    line-height: 150%;
    width: 100%;
    text-decoration: none;
    color: var(--Texto);
}

.no-border {
    border-bottom: 0px;
}

.option .textos {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
    justify-content: space-between;
}

.option .textos.row {
    flex-direction: row;
}

.option:last-child {
    border-bottom: 0px;
}

.option.icon_left {
    padding-left: 40px;
    width: calc(100% - 40px);
}

.option.icon_right {
    padding-right: 40px;
    width: calc(100% - 40px);
}

.option.icon_right.option.icon_left {
    width: calc(100% - 80px);
}

.option p {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option>i,
.option>input[type="checkbox"] {
    position: absolute;
    top: 30px;
}

.option>.left {
    left: 0px;
}

.option>.right {
    right: 0px;
}

.option.open .textos {
    flex-direction: column;
}

.option.open .textos p {
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
}

.option.open>i {
    transform: inherit;
}

.option .hide {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin: 15px 0px;
}

.option .hide {
    display: none;
}

.option .listas {
    margin-top: 15px;
}

.option .lista,
.option .lista ul {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.valoraciones {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    margin: 15px 0px 7px 0px;
    width: 100%;
}

.valoraciones span {
    background-color: var(--Gris-Valoraciones);
    height: 8px;
    width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
}

.valoraciones span.ok {
    background-color: var(--Azul);
}

.valoraciones span.small {
    background-color: transparent;
    display: flex;
    line-height: 90%;
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0.5;
}

.valoraciones span.small i {
    margin-right: 4px;
    margin-top: -3px;
    font-size: 15px;
}

.option .boton.whatsapp {
    position: absolute;
    top: 10px;
    right: 0px;
}

/*ARTICLES*/
article h2 {
    margin-bottom: 15px;
}

section>p {
    text-align: center;
}

article.fullwidth,
article.width60 {
    border-radius: var(--Radius-M);
    background-color: var(--Blanco);
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(8, 71, 150, 0.05);
    padding: 30px;
    align-self: flex-start;
    width: 58%;
    display: flex;
    flex-direction: column;
}

article.fullwidth {
    width: calc(100% - 60px);
}

/*ALERTS*/
.alert.fixed {
    position: fixed;
    right: 60px;
    bottom: 110px;
    z-index: 8;
    max-width: 400px;
}

.lity-content .alert {
    max-width: 600px;
}

.alert {
    border-radius: var(--Radius-S);
    background-color: var(--Azul);
    color: var(--Blanco);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
}

.alert h4 {
    color: var(--Blanco);
    font-weight: var(--SemiBold);
}

.alert .botones {
    width: 100%;
    column-gap: 10px;
    background-color: transparent;
    margin-bottom: 0px;
}

/*LEGAL PAGES*/
.legal h3 {
    margin-bottom: 15px;
}

.legal ul,
.legal ol {
    list-style-type: square;
    list-style-position: inside;
    margin-bottom: 20px;
}

.legal ol {
    list-style-type: decimal;
}

/*TOOLTIP*/
.tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
}

.tooltip:after {
    content: attr(title);
    background-color: var(--Titulares);
    color: var(--Blanco);
    text-align: center;
    border-radius: var(--Radius-S);
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    transition: var(--Transition);
    font-size: var(--S);
    opacity: 0;
    line-height: 120%;
    width: 200px;
}

.tooltip:before {
    top: -6px;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(45, 45, 45, 0);
    border-top-color: #2D2D2D;
    border-width: 7px;
    margin-left: -7px;
    opacity: 0;
    transition: var(--Transition);
}

.tooltip:hover:before,
.tooltip:hover:after {
    opacity: 1;
}

/*SETUP*/
section.setup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--Blanco);
    padding: 30px;
    border-radius: var(--Radius-L);
    align-content: flex-start;
    width: 585px;
    overflow: scroll;
    max-height: 70%;
}

section.setup.pass1 {
    align-content: center;
}

section.setup.pass2:after {
    position: sticky;
    content: "";
    bottom: -30px;
    left: 0px;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%) !important;
    backdrop-filter: inherit;
    z-index: 2;
}

.botonSubmit {
    border: none;
    border-color: transparent;
    background-color: transparent;
}

.error {
    font-family: 'Poppins', sans-serif;
    color: red;
    font-weight: var(--SemiBold);
    line-height: 135%;

}

/* Input file */
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 0.91em;
    font-weight: 300;
}

/*.inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    }*/
.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}

.inputfile+label * {
    pointer-events: none;
}

.input-group label,
label {
    color: var(--Texto);
    font-weight: var(--SemiBold);
    line-height: 150%;
    font-size: var(--M);
}

.overlayHideable.hidden {
    opacity: 0;
    visibility: hidden;
}
.overlayHideable {
    overflow: scroll;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s, visibility 0.2s;
}

.hideable.hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute; /* Saca el elemento del flujo visual */
    top: 0;
    left: 0;
    height: 0; /* Elimina su altura */
    overflow: hidden; /* Previene que el contenido se desborde */
    transition: opacity 0.2s, visibility 0.2s, height 0.2s;
}
.hideable {
    overflow: scroll;
    opacity: 1;
    visibility: visible;
    position: relative; /* Vuelve al flujo visual */
    height: auto; /* Restaura su altura cuando es visible */
    transition: opacity 0.2s, visibility 0.2s, height 0.2s;
}

.checkOption {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    line-height: 100%;
    width: 100%;
    text-decoration: none;
    color: var(--Texto);
}

.checkOption label {
    font-weight: 300;
}


#map {}

.map {
    width: 100%;
    height: 400px;
}

.flex_justify {
    flex-direction: justify;
}

div.menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--Texto);
    font-family: Poppins;
    font-size: var(--S);
    font-weight: var(--Regular);
    text-decoration: none;
    transition: var(--Transition);
    line-height: 150%;
    position: relative;
}

div.menu.selected,
div.menu:hover {
    color: var(--Azul);
}

div.menu::after {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 0px;
    height: 5px;
    background-color: var(--Azul);
    transform-origin: center;
    transition: var(--Transition);
}

div.menu:hover::after,
div.menu.selected::after {
    width: 65px;
}

div.menu i {
    font-size: var(--Icons);
}

i.pointer {
    cursor: pointer;
}

.optionImg {
    width: 100px;
    height: 100px;
}

ul.rgpd {
    list-style-type: none;
    margin-left: 20px;
}

ul.rgpd>li {
    text-indent: -5px;
    margin-bottom: 5px;
}

ul.rgpd>li:before {
    content: "-";
    text-indent: -20px;
}


.overlap-rgpd .text-p p {
    font-size: medium;
}

.overlap-rgpd .text-p span {
    font-size: medium;
}

.overlap-rgpd .text-p.medium {
    font-size: medium;
    line-height: 150%;
}

.overlap-rgpd .text-p-azul {
    color: var(--colorazul);
    font-family: Mulish;
    font-size: medium;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0;
    font-style: normal;
}

.overlap-rgpd .text-p .text-p-bold {
    font-weight: 700;
}

.overlap-rgpd .text-p a {
    text-decoration: none;
    color: var(--colorazul);
}

.overlap-rgpd .h5 {
    color: var(--texttitulares);
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /*line-height: 130%;*/
    letter-spacing: -1px;
}
.overlap-rgpd .h4 {
    color: var(--texttitulares);
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -1px;
}
